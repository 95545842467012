// closeEmailBindingAlert.js
'use strict';
import removeModals from '../action/removeModals.js';
import mergeMeData from '../action/mergeMeData.js';

/**
 * Close email binding alert
 * @kind action
 * @return {Promise} Action promise.
 */
const closeEmailBindingAlert = () => async dispatch => {
  dispatch(
    mergeMeData({
      field: 'closeEmailBindingAlertTimestamp',
      value: Date.now(),
    })
  );
  return dispatch(
    removeModals({
      ids: ['EmailBindingAlert'],
      transitionStatus: 'closing',
    })
  );
};

export default closeEmailBindingAlert;
