// setJoinDraftData.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

import setJoinAsset from '../action/setJoinAsset.js';

import { JoinField, PROOFS } from '../resource/joinConstants.js';
import { JoinFieldAssetPurposeMap } from '../resource/constantMap.js';

/**
 * Set join draft data
 * @kind action
 * @param {string} {key} - which field will be updated.
 * @param {object} {payload} - payload.
 * @return {Promise} Action promise.
 */
const setJoinDraftData =
  ({ key, payload = {} }) =>
  async dispatch => {
    const { value, error } = payload;
    if (error) {
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['join', 'draft', key],
          data: {
            error: {
              i18nKey: error.i18nKey,
              i18nOption: error.i18nOption,
            },
          },
        },
      });
    }
    if (JoinField.DOCUMENT_TYPE === key) {
      PROOFS.map(field =>
        dispatch(setJoinAsset({ purpose: JoinFieldAssetPurposeMap[field] }))
      );
    }
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['join', 'draft', key],
        data: {
          value,
          error: undefined,
        },
      },
    });
  };

export default setJoinDraftData;
