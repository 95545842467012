// submitMeKyc.js
'use strict';
import { withScope, captureException } from '@sentry/browser';

import fetch from '../resource/customFetch.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

import pushToastr from '../action/pushToastr.js';

import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';

import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getCurrentLanguage from '../resource/getCurrentLanguage.js';
import { localeConvertor } from '../resource/i18n.js';
import { PROOFS } from '../resource/joinConstants.js';
import { JoinFieldAssetPurposeMap } from '../resource/constantMap.js';

/**
 * Send me kyc
 * @kind action
 * @param {boolean} {isAgeVerify} - is age verify
 * @return {Promise} Action promise.
 */
const submitMeKyc =
  ({ isAgeVerify } = {}) =>
  async (dispatch, getState) => {
    const networkingSelectPath = ['submit', 'me', 'kyc'];
    try {
      const token = getMeData(getState(), 'token');
      const creator = getMeData(getState(), 'creator');
      if (!token) {
        return dispatch({ type: '' });
      }
      const proofObject = PROOFS.map(field => {
        const assetPurpose = JoinFieldAssetPurposeMap[field];
        const uploadJobId = getOperationData(
          getState(),
          ['join', 'draft', assetPurpose],
          'uploadJobId'
        );
        const assetIds =
          getOperationData(
            getState(),
            ['uploadJob', uploadJobId],
            'assetIds'
          ) || [];
        return {
          field,
          assetId: assetIds[0],
        };
      })
        .filter(item => !!item.assetId)
        .reduce((accumulator, item) => {
          const { field, assetId } = item;
          accumulator[field] = assetId;
          return accumulator;
        }, {});
      const body = isAgeVerify
        ? {
            ...proofObject,
          }
        : {
            ...proofObject,
            gender: creator.gender,
            country: creator.country,
          };
      const fetchOptions = {
        method: 'POST',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const url = getResourceUrl({ endpoint: '/me/kyc' });
      if (isAgeVerify) {
        url.searchParams.set('type', 'minimal');
      } else {
        url.searchParams.set('type', 'creator_v2');
      }
      const language = getCurrentLanguage();
      if (language) {
        url.searchParams.set(
          'lang',
          localeConvertor({
            locale: language,
            isISO639: true,
          })
        );
      }
      dispatch({
        type: SET_NETWORKING_FETCHING,
        payload: { selectPath: networkingSelectPath },
      });
      const response = await fetch(url.href, fetchOptions);
      if (!response.ok) {
        await handleFetchError({ response });
      }
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath },
      });
    } catch (error) {
      withScope(scope => {
        scope.setFingerprint(['join', error.message]);
        captureException(error);
      });
      let errorObject = {};
      try {
        errorObject = JSON.parse(error.message);
        // eslint-disable-next-line no-empty
      } catch (_) {}
      if (errorObject.status !== 429) {
        dispatch(
          pushToastr({
            textKey: errorObject.code
              ? `error_${errorObject.code.toLowerCase()}`
              : 'something_went_wrong',
            color: 'error',
          })
        );
      }
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default submitMeKyc;
