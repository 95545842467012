// fetchEmailDidYouMean.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';

const fetchEmailDidYouMean = async ({ email }) => {
  const url = getResourceUrl({ endpoint: '/address/validate' });
  url.searchParams.set('address', email);
  const fetchOptions = {
    headers: {
      ...getHeaders(),
    },
  };
  const response = await fetch(url.href, fetchOptions);
  const { did_you_mean: didYouMean } = await response.json();
  return didYouMean;
};

export default fetchEmailDidYouMean;
