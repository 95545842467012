// checkRegisterAccountUsernameIsValid.js
'use strict';
import debounce from 'lodash/debounce';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import fetchIsUsernameAvailable, {
  USERNAME_USED_ERROR_MESSAGE,
} from '../action/fetchIsUsernameAvailable.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { RE_USERNAME } from '../RemoteConfigKeys.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { accountRegister } from '../resource/validationI18nKey.js';

const selectPath = ['register', 'usernameValidation'];
const localValidator = new RegExp(/^[a-z0-9._]{3,20}$/);
let debouncedChecking = undefined;

export const clearDebouncedChecking = () => {
  debouncedChecking = undefined;
};

/**
 * Check is register account username valid
 * @kind action
 * @param {string} {username} - register username.
 * @return {Promise} Action promise.
 */
const checkRegisterAccountUsernameIsValid =
  ({ username }) =>
  async (dispatch, getState) => {
    if (!debouncedChecking) {
      debouncedChecking = debounce(async ({ username }) => {
        // if empty clear validation
        if (!username) {
          return dispatch({
            type: MERGE_OPERATION_DATA,
            payload: { selectPath, data: '' },
          });
        }

        // check formate
        const reUsername = getRemoteConfigData(getState(), RE_USERNAME);
        const validator = reUsername ? new RegExp(reUsername) : localValidator;
        const isUsernameFormatCorrect = validator.test(username);
        if (!isUsernameFormatCorrect) {
          return dispatch({
            type: MERGE_OPERATION_DATA,
            payload: {
              selectPath,
              data: accountRegister.usernameInvalidFormat,
            },
          });
        }

        // check is username exist
        await dispatch(fetchIsUsernameAvailable({ username }));

        const usernameError = getNetworkingData(
          getState(),
          ['register', 'isUsernameAvailable', username],
          'error'
        );
        if (!usernameError) {
          return dispatch({
            type: MERGE_OPERATION_DATA,
            payload: { selectPath, data: accountRegister.pass },
          });
        }

        if (usernameError?.message === USERNAME_USED_ERROR_MESSAGE) {
          return dispatch({
            type: MERGE_OPERATION_DATA,
            payload: {
              selectPath,
              data: accountRegister.usernameAlreadyExists,
            },
          });
        }
      }, 500); // TODO: remote config
    }
    debouncedChecking({ username });
  };

export default checkRegisterAccountUsernameIsValid;
