// updateLoginData.js
'use strict';
import isEmailFormatCorrect from '../resource/isEmailFormatCorrect.js';
import getLoginData from '../selector/getLoginData.js';
import getOperationData from '../selector/getOperationData.js';
import loadUrlOAuthLogin from './loadUrlOAuthLogin.js';
import { MERGE_OPERATION_DATA, SET_NETWORKING_IDLE } from '../ActionTypes.js';

const selectPath = ['login'];

/**
 * Update login data.
 * @kind action
 * @param {string} {dataKey} - data key.
 * @param {any} {value} - data value.
 * @param {boolean} {shouldAutoLogin} - whether or not to enable auto login while some criteria meet (e.g., the length of emailPin reaches 6)
 * @return {Promise} Action promise.
 */
const updateLoginData =
  ({ dataKey, value, shouldAutoLogin = true }) =>
  async (dispatch, getState) => {
    if ('phoneCountryCode' === dataKey || 'phoneNumber' === dataKey) {
      const loadPhoneNumberPackage = Promise.all([
        import('../partial/google-libphonenumber/PhoneNumberUtil.js'),
        import('../partial/google-libphonenumber/PhoneNumberFormat.js'),
      ]);

      const phoneNumber =
        'phoneNumber' === dataKey
          ? value?.replace(/[\b]/g, ' ')
          : getLoginData(getState(), 'phoneNumber');
      const phoneCountryCode =
        'phoneCountryCode' === dataKey
          ? value
          : getLoginData(getState(), 'phoneCountryCode');
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: { phoneNumber, phoneCountryCode },
          selectPath,
        },
      });

      const [{ PhoneNumberUtil }, { PhoneNumberFormat }] =
        await loadPhoneNumberPackage;
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      let phone = null;
      try {
        phone = phoneNumberUtil.parseAndKeepRawInput(
          phoneNumber,
          phoneCountryCode
        );
        // eslint-disable-next-line no-empty
      } catch (_) {}
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            isPhoneValidated: phone && phoneNumberUtil.isValidNumber(phone),
            e164PhoneNumber:
              phone && phoneNumberUtil.format(phone, PhoneNumberFormat.E164),
          },
          selectPath,
        },
      });
    }

    if ('email' === dataKey) {
      const formattedEmail = value?.replace(/[\b]/g, ' ');
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            email: formattedEmail,
            isEmailFormatCorrect: formattedEmail
              ? isEmailFormatCorrect({
                  email: formattedEmail,
                })
              : undefined,
          },
          selectPath,
        },
      });
    }

    if ('emailPin' === dataKey) {
      if (shouldAutoLogin && 5 < value.length) {
        const email = getLoginData(getState(), 'email');
        const url = new URL(location.pathname, location.origin);
        url.searchParams.set('action', 'login-email');
        url.searchParams.set('email', email);
        url.searchParams.set('pin', value);
        dispatch(loadUrlOAuthLogin({ url }));
      } else {
        dispatch({
          type: SET_NETWORKING_IDLE,
          payload: {
            selectPath: ['login', 'email', 'auth'],
          },
        });
      }

      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: { data: { [dataKey]: value }, selectPath },
      });
    }

    // Clear password validation when user changing password/username.
    if (['password', 'username'].includes(dataKey)) {
      const hasError = getOperationData(
        getState(),
        selectPath,
        'passwordValidation'
      );
      if (hasError) {
        dispatch({
          type: MERGE_OPERATION_DATA,
          payload: {
            selectPath,
            data: {
              ['passwordValidation']: '',
            },
          },
        });
      }
    }

    if ('username' === dataKey) {
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          data: {
            username: value?.toLowerCase().replace(/[\b]/g, ' '),
          },
          selectPath,
        },
      });
    }

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: { data: { [dataKey]: value }, selectPath },
    });
  };

export default updateLoginData;
