// loadUrlOAuthLogin.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import getLoginData from '../selector/getLoginData.js';
import pushToastr from '../action/pushToastr.js';
import handleLoginToken from '../action/handleLoginToken.js';
import addModal from '../action/addModal.js';
import fetchAccessToken, { viaTypes } from '../action/fetchAccessToken.js';

import {
  SET_NETWORKING_IDLE,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  MERGE_OPERATION_DATA,
  LOGOUT,
} from '../ActionTypes.js';
import { login as loginModal } from '../resource/modalId.js';

import { getPartnerName } from '../resource/partner.js';

const loginRegex = /^login-/gi; // TODO: remote config
// need to use whitelist here in case user try-error pattern from url.
const oAuthUrls = {
  twitter: '/login/twitter',
  facebook: '/login/facebook',
  email: '/login/email',
  sms: '/login/phone-number',
  'google-oauth2': '/login/google-oauth2',
  'apple-id': '/login/apple-id',
  discord: '/login/discord',
};

const partnerName = getPartnerName();

if (partnerName) {
  oAuthUrls[partnerName] = `/login/${partnerName}`;
}

/**
 * Load url oauth login
 * @kind action
 * @param {URL} {url} - url to load.
 * @param {bool} {isEmailAgreed} - is email agreed.
 * @param {string} {pathname} - new pathname after read url.
 * @param {bool} {isContinued} - whether login process is continued on popup.
 * @return {Promise} Action promise.
 */
const loadUrlOAuthLogin =
  ({ url, isEmailAgreed, pathname = location.pathname, isContinued = false }) =>
  async (dispatch, getState) => {
    if (!url) {
      return { type: '' };
    }

    const action = url.searchParams.get('action');
    if (!action) {
      return { type: '' };
    }

    const isOAuth = loginRegex.test(action);
    const oauth = isOAuth ? action.replace(loginRegex, '') : '';
    if (!Object.keys(oAuthUrls).includes(oauth)) {
      return { type: '' };
    }

    const email = getLoginData(getState(), 'email');

    if ('email' === oauth && !email) {
      const token = getMeData(getState(), 'token');
      if (token) {
        return dispatch(
          addModal({
            id: loginModal.SWITCH_ALERT,
            isHigherThanAll: true,
            transitionStatus: 'coming',
          })
        );
      } else {
        return dispatch(
          addModal({
            id: loginModal.EMAIL_REFILL,
            isHigherThanAll: true,
            transitionStatus: 'coming',
          })
        );
      }
    }

    const selectPath = ['login', oauth, 'auth'];

    const authUrl = getResourceUrl({ endpoint: oAuthUrls[oauth] });
    authUrl.search = url.search;
    if ('email' === oauth) {
      authUrl.searchParams.set('email', email);
    }

    const fetchOptions = {
      method: 'POST',
      headers: {
        ...getHeaders(),
      },
      credentials: 'same-origin',
    };

    const entry =
      getLoginData(getState(), 'entry') || authUrl.searchParams.get('entry');
    const isLogin = 'login' === entry;

    // As of 2024.08, we'll ask users if they agree to receive email from SWAG while signing up.
    if (!isLogin) {
      authUrl.searchParams.set('agree', Boolean(isEmailAgreed));
    }

    if (isLogin && !isContinued) {
      authUrl.searchParams.set('login_only', 1);
    }

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    let token = undefined;
    try {
      const response = await fetch(authUrl.href, fetchOptions);
      if (!response.ok) {
        await handleFetchError({ response });
      }

      if (isLogin && 202 === response.status) {
        dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
        return dispatch(
          addModal({
            id: 'StartRegisterAlert',
            isHigherThanAll: true,
            transitionStatus: 'coming',
          })
        );
      }

      dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
      dispatch({ type: LOGOUT });

      const { refresh_token: refreshToken } = await response.json();
      if (refreshToken) {
        await dispatch(
          fetchAccessToken({ refreshToken, via: viaTypes.JWT_LOGIN })
        );
        token = getMeData(getState(), 'token');
      }

      if (!token) {
        throw new Error('token_not_found');
      }

      history.replaceState(null, '', `${pathname}`);
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['login'],
          data: { entry },
        },
      });
      if (isContinued) {
        dispatch({
          type: MERGE_OPERATION_DATA,
          payload: {
            selectPath: ['login'],
            data: { isContinuedFromLogin: true },
          },
        });
      }
      dispatch(
        pushToastr({
          textKey: 'toast_signup_success',
          durationSec: 3,
        })
      );
      return dispatch(handleLoginToken({ token }));
    } catch (error) {
      setTimeout(
        () => dispatch({ type: SET_NETWORKING_IDLE, payload: { selectPath } }),
        1500 // TODO: remote config
      );
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default loadUrlOAuthLogin;
