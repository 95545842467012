// redoOcr.js
'use strict';
import { REMOVE_OPERATION_DATA } from '../ActionTypes.js';

import { replace } from '../action/navigationAction.js';
import setJoinAsset from '../action/setJoinAsset.js';

import { Progress as JoinProgress, PROOFS } from '../resource/joinConstants.js';
import { JoinFieldAssetPurposeMap } from '../resource/constantMap.js';

/**
 * Redo ocr
 * @kind action
 * @param {boolean} {isAgeVerify} - is age verify
 * @return {Promise} Action promise.
 */
const redoOcr =
  ({ isAgeVerify } = {}) =>
  async dispatch => {
    PROOFS.map(field =>
      dispatch(setJoinAsset({ purpose: JoinFieldAssetPurposeMap[field] }))
    );
    if (!isAgeVerify) {
      dispatch({
        type: REMOVE_OPERATION_DATA,
        payload: {
          selectPath: ['join', 'kyc'],
        },
      });
    }
    dispatch({
      type: REMOVE_OPERATION_DATA,
      payload: {
        selectPath: ['join', 'draft'],
      },
    });
    return dispatch(
      replace(
        `/${isAgeVerify ? 'age-verify' : 'join'}/${
          JoinProgress.UPLOAD_IDENTIFICATION
        }${window.location.search}`
      )
    );
  };

export default redoOcr;
