// fetchIsUsernameAvailable.js
'use strict';
import fetch from '../resource/customFetch.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

export const USERNAME_USED_ERROR_MESSAGE = 't:username_already_taken';

/**
 * Fetch is username available
 * @kind action
 * @param {string} {username} - action param.
 * @return {Promise.resolve(bool)} is username avaliable
 */
const fetchIsUsernameAvailable =
  ({ username }) =>
  async (dispatch, getState) => {
    const selectPath = ['register', 'isUsernameAvailable', username];

    const isUsernameFetched = getNetworkingData(
      getState(),
      selectPath,
      'isFetched'
    );
    if (isUsernameFetched) {
      const usernameError = getNetworkingData(getState(), selectPath, 'error');
      if (USERNAME_USED_ERROR_MESSAGE === usernameError?.message) {
        return dispatch({ type: '' });
      }

      const usernameFetchedTimestamp = getNetworkingData(
        getState(),
        selectPath,
        'fetchedTimestamp'
      );
      if (Date.now() < usernameFetchedTimestamp + 1000) {
        return dispatch({ type: '' });
      }
    }

    const url = getResourceUrl({ endpoint: `/u/${username}` });
    const fetchOptions = {
      method: 'HEAD',
      headers: {
        ...getHeaders(),
      },
    };

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      const response = await fetch(url.href, fetchOptions);

      const isUsernameUsed = response.ok;
      if (isUsernameUsed) {
        throw new Error(USERNAME_USED_ERROR_MESSAGE);
      }

      if (404 !== response.status) {
        await handleFetchError({ response });
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { error, selectPath },
      });
    }
  };

export default fetchIsUsernameAvailable;
