// isEmailFormatCorrect.js
'use strict';

// https://stackoverflow.com/a/46181
const emailRegex = // TODO: remote config
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmailFormatCorrect = ({ email }) => emailRegex.test(email);

export default isEmailFormatCorrect;
