// verifyRegisterPasswordFormat.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import verifyPasswordFormat from '../resource/verifyPasswordFormat.js';

import { accountRegister } from '../resource/validationI18nKey.js';

const selectPath = ['register', 'passwordValidation'];
/**
 * Check register account password is valid
 * @kind action
 * @param {string} {password} - password username.
 * @return {Promise} Action promise.
 */
const verifyRegisterPasswordFormat =
  ({ password }) =>
  async dispatch => {
    if (!password) {
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: { selectPath, data: '' },
      });
    }

    const isLegalText = verifyPasswordFormat({ password });
    const data = isLegalText
      ? accountRegister.pass
      : accountRegister.passwordInvalidFormat;
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: { selectPath, data },
    });
  };

export default verifyRegisterPasswordFormat;
