// loadUrlQueryUtm.js
'use strict';
import getModalData from '../selector/getModalData.js';
import getMeData from '../selector/getMeData.js';
import mergeMeData from '../action/mergeMeData.js';
import login from '../action/login.js';
import { login as loginModal } from '../resource/modalId.js';
import {
  updateMixpanelUtm,
  getMixpanelDistinctId,
} from '../resource/mixpanel.js';
import {
  getCurrentUtm,
  setTrackHeader,
} from '../resource/fetchOptionHeader.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Load utm persistent
 * @kind action
 * @param {URLSearchParams} {searchParams} - search param.
 * @return {Promise} Action promise.
 */
const loadUrlQueryUtm =
  ({ searchParams }) =>
  async (dispatch, getState) => {
    const utm = getMeData(getState(), 'utm');
    const utmData = getCurrentUtm({ searchParams, existUtm: utm });

    setTrackHeader({
      utmObject: utmData,
      trackObject: { mixpanel_distinct_id: getMixpanelDistinctId() },
    }); // X-Track
    updateMixpanelUtm({ utm: utmData }); // mixpanel
    dispatch(mergeMeData({ field: 'utm', value: utmData })); // redux

    const shouldSetReferral = searchParams
      .get('utm_term')
      ?.startsWith('referral_');
    const referralCode = searchParams.get('utm_term')?.startsWith('referral_')
      ? searchParams.get('utm_term')?.replace(/^referral_/, '')
      : '';
    if (shouldSetReferral) {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['acquisition', 'referral'],
          data: {
            code: referralCode,
          },
        },
      });
      const token = getMeData(getState(), 'token');
      const isModalOpened =
        'display' ===
        getModalData(getState(), loginModal.METHOD_SELECT, 'status');
      const isOnJoin = getIsMatchedPathname({
        pathname: window.location.pathname,
        dataKey: 'isOnJoin',
      });
      // there is no need to open sign up modal since join page can sign up directly.
      if (!token && !isModalOpened && !isOnJoin) {
        return dispatch(login());
      }
    }

    return dispatch({ type: '' });
  };

export default loadUrlQueryUtm;
