// getEmailCooldownTimestamp.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';

/**
 * Select email cooldown msec by select path
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {Array} selectPath - select path.
 * @param {string} email - email.
 * @return {number} The selected email cooldown msec.
 */
const getEmailCooldownTimestamp = createCachedSelector(
  (state, selectPath, email) => getOperationData(state, selectPath, email),
  (state, selectPath, email) => getNetworkingData(state, selectPath, email),
  (operations, networkings) => {
    const sentCount = operations?.sentCount || 0;
    const cooldownUnit = operations?.cooldownUnit * 1000;
    const fetchedTimestamp = networkings?.fetchedTimestamp || 0;
    const error = networkings?.error;

    if (!sentCount || error) {
      return 0;
    }

    return sentCount * cooldownUnit + fetchedTimestamp;
  }
)((state, selectPath, email) => `${selectPath.join(':')}:${email}`);

export default getEmailCooldownTimestamp;
