// constantMap.js
'use strict';
import { JoinField } from '../resource/joinConstants.js';
import { Purpose as AssetPurpose } from '../resource/assetConstants.js';

export const JoinFieldAssetPurposeMap = {
  [JoinField.FACE]: AssetPurpose.KYC_FACE,
  [JoinField.DOCUMENT]: AssetPurpose.KYC_DOCUMENT,
  [JoinField.DOCUMENT_BACK]: AssetPurpose.KYC_DOCUMENT_BACK,
};
