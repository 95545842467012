// verifyPasswordFormat.js
'use strict';

/**
 * Verify password format
 * @param {string} password - param.
 * @return {boolean}
 */
const verifyPasswordFormat = ({ password }) =>
  /(?=.*[\d])(?=.*[a-zA-Z]).{6,}/.test(password);

export default verifyPasswordFormat;
