// setPasswordVerifyStatus.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Set password verify status
 * @kind action
 * @param {boolean} {hasError} - password verification has error.
 * @return {Promise} Action promise.
 */
const setPasswordVerifyStatus =
  ({ hasError }) =>
  async dispatch => {
    return dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['passwordVerify'],
        data: { hasError },
      },
    });
  };

export default setPasswordVerifyStatus;
